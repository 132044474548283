@media screen and (max-device-width: 767px) and (orientation: portrait) {

    html {
        overflow-x: hidden;
    }

    /* NavBar start */
    .headerNavBar {
        display: none;
    }

    .open_mobile_nav {
        display: block;
    }

    /* NavBar end */


    /* Header start */

    .headerLogo {
        transform: scale(0.6);
        top: -20vw;
        right: -17.5vw;
    }

    .headerHeroheading {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        gap: 5vh;
    }

    .headerHeroheading h6 {
        font-size: 3vw;
        font-weight: normal;
        display: block;
        text-align: center;
    }

    .headerHeroheading h6::after {
        display: none;
    }

    .large_Heading_h2 {
        display: none;
    }

    .small_Heading_h2 {
        padding: 0vw 2.5vw;
        width: 100vw;
        display: block;
        font-size: 12vw;
        text-align: center;
    }

    .headerHeroheading p {
        width: 75vw;
        font-size: 3vw;
        text-align: center;
        margin: 0 auto;
    }

    .headerQuickNavbar {
        bottom: 5%;
        left: 47.5%;
        transform: translate(-50%, -50%);
        gap: 15vw;
    }


    .headerQuickNavbar div {
        /* width: 15vw; */
        font-size: 3vw;
    }


    .headerQuickNavbar div span {
        display: none;
    }

    .headerQuickNavbar div:hover::before {
        width: 2vw;
    }


    /* Header end */

    /* About us start */

    .aboutUs_Section {
        padding-left: 10vw;
        padding-top: 10vh;
    }


    .aboutUs-heading {
        width: 65%;
        font-size: 8vw;
    }


    .aboutUs-detail {
        width: 60%;
        margin-left: 20vw;
        font-size: 3vw;
    }

    .abt_small_img {
        display: block;
    }

    .abt_large_img {
        display: none;
    }



    .aboutUs-imgContainer {
        display: flex;
        flex-direction: column;
        gap: 5vh;
        height: 100%;
        margin-top: 15vh;
    }

    .aboutImgAnim1-container {
        width: 40vw;
        height: 30vh;
    }

    .about_subImgContainer .aboutImgAnim1 {
        width: 40vw;
        height: 30vh;
    }

    .about_subImgContainer .aboutImgAnim2 {
        width: 40vw;
        height: 30vh;
    }

    .aboutImgAnim3 {
        width: 82vw;
        object-fit: cover;
    }

    .aboutServices {
        /* background-color: aqua; */
        margin-top: 15vh;
        gap: 10vh;
    }


    .servicesTitle h5 {
        font-size: 8vw;
        font-family: "sudregular";
        font-weight: normal;
    }

    .servicesBox {
        padding-top: 0vh;
        display: grid;
        grid-gap: 7.5vh;
        grid-template-columns: 1fr 1fr;
    }

    .serviceBox {
        display: flex;
        flex-direction: column;
        gap: 2vh;
    }

    .serviceNum img {
        width: 20%;
    }

    .serviceTitle {
        margin-top: -1vh;
        font-size: 4vw;
        font-family: "radikalregular";
        font-weight: normal;

    }

    .serviceBox p {
        width: 80%;
        text-align: left;
        font-size: 2vw;
    }

    /* About us end  */

    /* Rooms section start */


    .roomsHeading {
        padding: 20vh 0vh 15vh 10vw;
        display: flex;
        flex-direction: column;
        gap: 8vh;
    }


    .roomsTitle {
        font-size: 8vw;

    }

    .roomsText {
        margin-left: 15vw;
        width: 65%;
        font-size: 3vw;
    }

    .roomsSection {
        position: relative;
        width: 100%;
        height: 50vh;
        overflow: hidden;
    }


    .image-slider {
        z-index: 0;
        margin: 0 auto;
        padding: 0;
        width: 100%;
        height: 50vh;
    }

    .image-slide {
        height: 50vh;
        margin: 0 auto;
        filter: brightness(90%);
    }


    .slick-slide.slick-center {
        /* transform: scale(1.25); */
        transition: transform 0.8s 1.4s cubic-bezier(0.84, 0, 0.08, 0.99);
    }

    .block-1 {
        z-index: 1;
        position: absolute;
        height: 50vh;
        width: 5%;
        left: 0%;
        background: rgb(20, 20, 20);
    }

    .block-2 {
        z-index: 1;
        position: absolute;
        height: 50vh;
        width: 22.5%;
        left: 25%;
        background: rgb(20, 20, 20);
    }

    .block-3 {
        z-index: 1;
        position: absolute;
        height: 50vh;
        width: 5%;
        right: 8%;
        background: rgb(20, 20, 20);
    }


    .text-slider-wrapper {
        z-index: 2;
        position: absolute;
        width: 100%;
        top: 10%;
    }

    .text-slide h1 {
        font-size: 5vw;
        font-weight: lighter;
        text-transform: uppercase;
        padding-left: 2.5%;
    }

    .text-slide h6 {
        font-size: 3vw;
        margin-top: 5%;
        text-align: left;
        margin-left: 7.5%;
        flex-direction: column;
        gap: 2.5vh;
        position: relative;
        font-family: "radikalregular";
        font-weight: normal;
    }

    .text-slide a {
        z-index: 1;
    }


    .slider-control {
        display: none;
    }

    /* Rooms section end */

    /* Dining section start */

    .diningSection {
        width: 100vw;
        height: 100%;
        padding: 20vh 0;
        display: flex;
        flex-direction: column;
        gap: 5vh;
    }

    .diningTitle {
        font-size: 8vw;
        padding-left: 10vw;
    }

    .diningModalTitle {
        font-size: 4.25vw;
    }

    .diningModalHeading {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 5vh;
    }

    .diningModalHeading span {
        width: 80%;
        text-align: left;
        font-size: 3vw;
    }

    .diningNav a {
        text-decoration: none;
        color: var(--color-white);
        padding-right: 6vw;
        position: relative;
        font-size: 3.5vw;
    }


    .diningModal img {
        margin-top: 5vh;
        width: 92.5%;
        height: 50vh;
        object-fit: cover;
    }

    /* Dining section end */

    /* Gallery section start */
    .gallerySection {
        width: 100vw;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10vh;
    }

    .galleryHeroDiv {
        gap: 2vw;
    }


    .galleryHeroImg img {
        width: 50vw;
        height: 45vh;
        object-fit: cover;
    }

    .galleryImgDiv img {
        width: 45vw;
        object-fit: cover;
    }

    .galleryTitle {
        position: absolute;
        bottom: 1vh;
        left: 2vw;
        font-size: 10vw;
        font-family: "sudregular";
    }

    .galleryImgDetail {
        font-size: 2.25vw;
        padding-left: .5vw;
    }

    .galleryContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5vh;
    }

    /* Gallery section end */


    /* contact section start */

    .contactSection {
        padding-left: 10vw;
        padding-top: 20vh;
    }

    .contactTitle {
        font-size: 8vw;
        font-family: "sudregular";
        letter-spacing: 0.2vw;
        font-weight: normal;
    }

    .contactSection .locationMap {
        margin-top: 10vh;
        width: 77.5vw;
        height: 60vh;
    }

    .contactDetails {
        padding: 15vh 10vw 15vh 0vw;
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }

    .contactDetails div {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 3vh;
    }

    .contactDetails div .contactDetailsTitle {
        font-size: 4vw;
    }


    .contactDetails div h6 {
        font-size: 3vw;
        font-weight: normal;
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: left;
    }

    .contactDetails div:nth-of-type(1) p {
        width: 100%;
        font-size: 3vw;
    }

    .contactDetails div:nth-of-type(2) div {
        margin-top: 0vh;
        display: flex;
        flex-direction: column;
        gap: 0.5vh;
    }

    .contactDetails div:nth-of-type(3) div {
        margin-top: 0vh;
        display: flex;
        flex-direction: column;
        gap: 0.5vh;
    }

    /* contact section end */


    /* Footer section start */

    footer h6 {
        margin-left: 10vw;
        font-size: 3vw;
    }

    .footerNav {
        margin-left: 7.5vw;
        display: grid;
        grid-template-columns: 7.5vw 7.5vw;
        grid-gap: 2.5vh;
    }

    .footerNav a {
        font-size: 2.5vw;
    }


    .footerSocial {
        gap: 8vw;
        font-size: 2.5vw;
        left: 10vw;
    }

    .footerEnd {
        width: 70%;
        margin-left: 7.5vw;
        font-size: 2.15vw;
    }

    /* Footer section end */

}


@media only screen and (min-width: 321px) and (max-device-width: 767px) and (orientation: landscape) {

    html {
        overflow-x: hidden;
    }


    .headerLogo {
        transform: scale(0.6);
        top: -12.5vw;
        right: -10.5vw;
    }

    .headerNavHover {
        display: none;
    }
}


@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {

    html {
        overflow-x: hidden;
    }

    .headerLogo {
        transform: scale(1);
        top: -4vw;
        right: 0vw;
    }


    /* Navbar start */
    .headerNavBar {
        top: 50%;
        height: 100vh;
        width: 8vw;
        gap: 7%;

    }

    .socialsDiv {
        bottom: 7.5%;
        font-weight: light;
        font-size: 1.2vw;
    }

    .navBookBtn {
        top: 0%;
        width: 8.55vw;
        height: 9vw;
    }

    .navBookBtn .navBookText {
        font-size: 1.75vw;
    }

    .navMenuBg {
        width: 4.8vw;
        height: 4.8vw;
    }

    .openMenu {
        width: 4.5vw;
        height: 4.5vw;
    }

    .openMenu span {
        border-radius: 0.6510416666666666vw;
        height: .75vh;
        margin: 0.35vh 0;
    }

    .headerRoomIcon,
    .headerLocationIcon {
        transform: scale(1.75);
    }

    .modalContainer {
        width: 44vw;
    }

    .modal_heading {
        margin: 3vh 0vh 2vh 0vh;
        width: 40vw;
        height: 20vh;
    }

    .modal_title {
        font-size: 3vw;
    }

    .modal_box {
        width: 40vw;
        padding-top: 2vh;
        padding-bottom: 2vh;
        margin-top: 5vh;
        z-index: 20;
    }

    .modal_box h6 {
        font-size: 2.25vw;
    }

    .modal_box a {
        font-size: 1.75vw;
        margin-top: 3vh;
    }

    .modal_box img {
        width: 40vw;
        height: 40vh;
        object-fit: cover;
        margin-top: 2.5vh;
    }

    .locationModalMap {
        width: 40vw;
        height: 60vh;
    }

    .modal_box p {
        font-size: 2vw;
    }

    /* Navbar end */

    /* menu container start */
    .icon-link {
        font-size: 2.5vw;
        width: 5vw;
        height: 5vw;
    }

    .menu-grid-2 ul {
        font-size: 4.5vw;
    }

    .menu-grid-2 .link {
        height: 4.5vw;
    }

    .menu-grid-2 .mask {
        height: 2.75vw;
    }

    .menu-grid-2 .title {
        font-size: 3.25vw;
    }

    .menu-grid-2 .title span {
        font-size: 2vw;
        padding-right: 2vw;
    }


    .menu-grid-3 {
        gap: 15vh;
        font-size: 2.75vw;
    }

    /* menu container end */

    .headerNavHover {
        display: none;
    }

    /* header start */
    .headerHeroheading {
        top: 52.5%;
        left: 15.5vw;
        gap: 4vh;
    }

    .large_Heading_h2 {
        font-size: 7.5vw;
    }

    .headerHeroheading p {
        width: 80%;
        font-size: 2vw;
    }

    .headerQuickNavbar {
        position: absolute;
        bottom: 20vw;
        left: 15.5vw;
        gap: 12.5vw;
    }

    .headerQuickNavbar div {
        font-size: 2vw;
    }


    /* header end */


    /* AboutUs Section Start */

    .aboutUs_Section {
        padding-left: 13.5vw;
        padding-top: 17.5vh;

    }

    .aboutUs-heading {
        font-size: 5vw;
    }

    .aboutUs-detail {
        width: 55%;
        margin-left: 25vw;
        font-size: 2.25vw;
    }

    .about_subImgContainer {}


    .aboutImgAnim1-container {
        width: 40vw;
        height: 45vh;
    }

    .about_subImgContainer .aboutImgAnim1 {
        width: 40vw;
        height: 45vh;
        object-fit: cover;
    }

    .about_subImgContainer .aboutImgAnim2 {
        width: 40vw;
        height: 35vh;
        object-fit: cover;
    }

    .aboutImgAnim3 {
        width: 82.5vw;
        height: 40vh;
        object-fit: cover;
    }

    .servicesTitle h5 {
        font-size: 5vw;
    }

    .servicesBox {
        padding-top: 0vh;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10vw;
    }

    .serviceNum img {
        width: 15%;
    }

    .serviceTitle {
        font-size: 2.5vw;
    }

    .serviceBox p {
        width: 75%;
        font-size: 1.5vw;
    }

    /* AboutUs Section end */


    /* Rooms section start */

    .roomsHeading {
        padding: 20vh 0vh 15vh 14.5vw;
        display: flex;
        flex-direction: column;
        gap: 8vh;
    }

    .roomsTitle {
        font-size: 5vw;
    }


    .roomsText {
        margin-left: 20vw;
        width: 60%;
        font-size: 2.25vw;
        letter-spacing: 0.05vw;
    }

    .roomsSection {
        position: relative;
        width: 100%;
        height: 70vh;
        overflow: hidden;
    }

    .image-slider {
        height: 70vh;
    }

    .image-slide {
        height: 70vh;
    }


    .text-slider-wrapper {
        top: 15.5%;
    }

    .text-slide h1 {
        font-size: 4vw;
        padding-left: 12.5%;
    }

    .text-slide h6 {
        font-size: 2.25vw;
        margin-top: 5%;
        gap: 3.75vh;
    }

    .slider-control {
        margin: 0%;
        bottom: 0%;
        left: 17.5%;
    }

    .slider-control button {
        padding: 1.5vw;
        border: 0.052083333333333336vw solid rgba(255, 255, 255, 0.3);
        font-size: 1.75vw;
        margin: 0.8vw;
    }

    /* Rooms section end */

    /* Dining section start */

    .diningTitle {
        font-size: 5vw;
        padding-left: 13.5vw;
    }

    .diningModal {
        margin-top: 0vh;
        padding-left: 13.5vw;
        padding-right: 2vw;
        gap: 5vh;
        position: relative;
    }

    .diningModalHeading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5vh;

    }

    .diningModalHeading span {
        width: 85%;
        text-align: justify;
    }

    .diningModal img {
        height: 50vh;
    }

    /* Dining section end */

    /* Gallery section start */

    .galleryHeroImg img {
        width: 50vw;
        height: 60vh;
        object-fit: cover;
    }

    .galleryTitle {
        left: 13.5vw;
        font-size: 8vw;
    }

    .galleryImgDiv img {
        width: 40vw;
        object-fit: cover;
    }

    .galleryContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10vh;
    }

    .galleryImgDetail {
        font-size: 2vw;
    }


    /* Gallery section end */

    /* contact section start */

    .contactTitle {
        font-size: 5vw;
    }

    .contactDetails {
        padding: 15vh 10vw 15vh 5vw;
        gap: 7.5vh;
    }

    .contactDetails div .contactDetailsTitle {
        font-size: 2.35vw;
    }

    .contactDetails div h6 {
        font-size: 1.55vw;
    }

    .contactDetails div:nth-of-type(1) p {
        width: 100%;
        font-size: 1.55vw;
    }

    /* contact section end */

    /* Footer section start */

    footer {
        width: 100vw;
        height: 100%;
        background-color: #181818;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 5vh;
        padding-bottom: 5vh;
        position: relative;
    }


    footer h6 {
        font-size: 2.25vw;
    }

    .footerNav {
        margin-left: 7.5vw;
        display: grid;
        grid-template-columns: 7.5vw 7.5vw;
        grid-gap: 1.5vh;
    }

    .footerNav a {
        font-size: 1.75vw;
    }

    .footerSocial a {
        font-size: 1.75vw;
    }

    .footerEnd {
        font-size: 1.25vw;
    }


    /* Footer section end */


}